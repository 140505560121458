import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import { MdInsights } from "react-icons/md"
import LocalHospitalIcon from "@material-ui/icons/LocalHospital"
import CartTab from "../userSettings/cartTab"
import OrderTab from "../userSettings/orderTab"
import MedTab from "../userSettings/medsTab"
import AfyaTab from "../userSettings/afyaTab"
import ServBg from "../serviceSection/serv.svg"
import ShoppingCartCheckout from "@mui/icons-material/ShoppingCartCheckout"
import MedicationLiquid from "@mui/icons-material/MedicationLiquid"

const TabContainer = styled.div`
  background-image: url(${ServBg});
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`
const AfyaTabCont = styled.div`
  @media screen and (min-width: 320px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (min-width: 768px) {
    height: 100%;
  }
`

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <TabContainer
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <AfyaTabCont>{children}</AfyaTabCont>
        </Box>
      )}
    </TabContainer>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  }
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={event => {
        event.preventDefault()
      }}
      {...props}
    />
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100vw",
    backgroundColor: theme.palette.background.paper,
  },
  Tab: {
    backgroundColor: "#1d9682",
    indicatorColor: "	#00b300",
    [theme.breakpoints.up("xs")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1em",
    },
  },
}))

export default function NavTabs() {
  const classes = useStyles()
  const [value, setValue] = React.useState(3)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div >
      <AppBar position="static">
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
        >
          <LinkTab
            className={classes.Tab}
            icon={<LocalHospitalIcon />}
            label="Medications"
            href="/drafts"
            {...a11yProps(0)}
          />
          <LinkTab
            className={classes.Tab}
            icon={<MedicationLiquid />}
            label="Appointments"
            href="/trash"
            {...a11yProps(1)}
          />
          <LinkTab
            className={classes.Tab}
            icon={<MdInsights style={{ width: "30px", height: "30px" }} />}
            label="Progress notes"
            href="/spam"
            {...a11yProps(2)}
          />
          <LinkTab
            className={classes.Tab}
            icon={<LocalHospitalIcon />}
            label="Afya Tab"
            href="/spam"
            {...a11yProps(3)}
          />
        </Tabs>
      </AppBar>
      <TabPanel style={{padding:"1rem"}} value={value} index={0}>
        <MedTab />
      </TabPanel>
      <TabPanel style={{padding:"1rem"}} value={value} index={1}>
        <OrderTab />
      </TabPanel>
      <TabPanel style={{padding:"1rem"}} value={value} index={2}>
        <CartTab />
      </TabPanel>
      <TabPanel style={{marginBottom:"2rem"}} value={value} index={3}>
        <AfyaTab />
      </TabPanel>
    </div>
  )
}


