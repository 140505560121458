import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  margin: 0 auto;
  height: 80vh;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: auto;
  outline: none;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &:focus {
    border-color: #1d9682;
    box-shadow: 0 0 0 2px rgba(29, 150, 130, 0.2);
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 50vh;
    padding: 10px;
  }
`

export const Header = styled.div`
  font-size: 1.2em;
  border-radius: 8px 8px 0 0;
  background-color: #edf9ee;
  padding: 7px 9px;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 1em;
    padding: 5px 7px;
  }
`

export const NoteList = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-bottom: 20px;
`

export const NoteItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #e1ffc7;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 5px;
    font-size: 14px;
  }
`

export const NoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding-right: 40px;
`

export const NoteText = styled.p`
  margin: 0;
  padding: 0;
  flex: 1;
`

export const NoteTime = styled.span`
  display: block;
  margin-top: 5px;
  font-size: 12px;
  color: #666;
`

export const DateGroup = styled.div`
  margin-bottom: 20px;
`

export const DateHeader = styled.h3`
  margin: 0 0 10px;
  font-size: 16px;
  color: #333;
`

export const OptionsContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`

export const ThreeDots = styled.div`
  cursor: pointer;
  font-size: 20px;
`

export const Options = styled.div`
  position: absolute;
  top: 25px;
  right: 0;
  background: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 100;
`

export const Option = styled.div`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`

export const InputArea = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 10px;
`

export const EditArea = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`

export const Button = styled.button`
  position: absolute;
  box-shadow:
    0 1px 2px rgb(60 64 67 / 30%),
    0 1px 3px 1px rgb(60 64 67 / 15%);
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #1d9682;
  font-size: 15px;
  outline: 2.5px solid #1d9682;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  z-index: 1;

  &:hover {
    background: #2e4158;
    color: #e9ecef;
    outline: 1px solid #2e4158;
  }

  @media (max-width: 600px) {
    right: 5px;
    font-size: 14px;
    padding: 6px 12px;
  }
`

export const TextareaContainer = styled.div`
  flex: 1;
  display: flex;
`

export const Textarea = styled.textarea`
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  resize: none;
  height: 60px;
  overflow: hidden;
  outline: none;

  &:focus {
    border-color: #1d9682;
    box-shadow: 0 0 0 2px rgba(29, 150, 130, 0.2);
  }

  @media (max-width: 600px) {
    height: 50px;
    font-size: 14px;
  }
`

export const EditTextArea = styled.textarea`
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  resize: none;
  height: 100px;
  overflow: auto;
  outline: none;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &:focus {
    border-color: #1d9682;
    box-shadow: 0 0 0 2px rgba(29, 150, 130, 0.2);
  }

  @media (max-width: 600px) {
    height: 80px;
    font-size: 14px;
  }
`

export const NoNotesMessage = styled.p`
  text-align: center;
  color: #888;
  font-size: 18px;
  font-style: italic;
  padding: 20px;

  @media (max-width: 600px) {
    font-size: 16px;
    padding: 10px;
  }
`
