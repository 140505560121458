import styled from 'styled-components';
import { Accordion } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import { BsSave } from 'react-icons/bs';

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Oswald", sans-serif;
  overflow: hidden;


  @media screen and (min-width: 320px) {
    
  }
  @media screen and (min-width: 768px) {
    
  }
  @media screen and (min-width: 1024px) {
    
  }
  @media screen and (min-width: 1440px) {
   
  }
`;

export const UserWrapper = styled.div`
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 2px 1px #a3d4cb;
  width: 90vw;
  max-width: 800px; 

  @media screen and (min-width: 768px) {
    width: 70vw;
  }
`;

export const SettingsBtn = styled(Accordion.Header)`
  box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  background-color: white;
  margin: 2em 0 0.5em;
  font-family: "Oswald", sans-serif;
`;

export const ProfileCard = styled.div`
  padding: 20px;
  margin: 30px 20px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 320px) {
    padding: 10px;
  }
`;

export const CardHeader = styled.div`
  background: #d9ede9b0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px 8px 0 0;
  margin-bottom: 20px;
`;

export const Heading = styled.h3`
  margin-left: 10px;
  color: #1d9682;
`;

export const TopButton = styled(Button)`
  box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  border-radius: 8px;
  white-space: nowrap;
  color: #1d9682;
  font-size: 15px;
  border: 2.5px solid #1d9682;
  outline: none;
  cursor: pointer;
  padding: 5px 15px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-right: 10px;
  align-self: end;

  &:hover {
    background: #2e4158;
    color: #f8f9fa;
  }

  @media screen and (min-width: 1440px) {
    margin: 2em 0 0;
  }
`;

export const ProfPic = styled.div`
  background: #e5f3f0;
  color: #1d9682;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  border: 5px solid #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 2.5em;

  @media screen and (min-width: 1440px) {
    width: 10em;
    height: 10em;
  }
`;

export const UserInitials = styled.span`
  font-size: 4em; 
  color: #1d9682;

  @media screen and (min-width: 1440px) {
    font-size: 5em; 
  }
`;

export const Hr = styled.hr`
  background-color: #b4b4b4;
  border: none;
  height: 1px;
  width: 80%;
  margin: 10px auto; 
`;

export const SaveIcon = styled(BsSave)`
  color: #1d9682;
  margin-left: 12px;
`;

export const NotesCont = styled.div`
  font-family: "Oswald", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; 
  padding: 10px; 
`;

export const NotesWrapper = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 1200px; 
`;

export const Header = styled.h2`
  margin: 0; 
`;

export const HeroImg = styled.img`
  width: 100%;
  max-width: 20em; 
  height: auto; 
  margin: 20px 0;
`;
