import React, { useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Tabs, Tab, Box } from "@material-ui/core"
import MyNotes from "../userSettings/MyNotes"
import ProviderNotes from "../userSettings/ProviderNotes"
import Documents from "../userSettings/Documents"

const TabContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <TabContainer
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </TabContainer>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export default function NotesTab() {
  const [value, setValue] = useState(0)

  const handleChange = (_event, newValue) => {
    setValue(newValue)
  }

  return (
    <AfyaContainer>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="progress notes tabs"
            variant="scrollable"
            scrollButtons="on"
          >
            <Tab label="My Notes" />
            <Tab label="Provider Notes" />
            <Tab label="Documents" />
          </Tabs>
      
      <TabPanel value={value} index={0}>
        <MyNotes /> 
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProviderNotes /> 
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Documents /> 
      </TabPanel>
    </AfyaContainer>
  )
}

const AfyaContainer = styled.div`
  height: 100%;
  width: 100vw;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding:10px;
  margin-bottom: 2.5rem;
  overflow-x: hidden;
 
  @media screen and (min-width: 320px) {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
  }
  @media screen and (min-width: 768px) {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
  }
  @media screen and (min-width: 1024px) {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
  }
`