import styled from 'styled-components'

export const Container = styled.div`
  padding: 20px;
  width: 600px;
  min-height: 100vh;
  max-height: 100%; 
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @media (max-width: 768px) {
    width: 90%; 
    min-height: 90vh; 
    padding: 15px; 
  }

  @media (max-width: 600px) {
    width: 100%;
    min-height: 95vh; 
    padding: 10px;
  }
`;



export const Heading = styled.div`
  font-size: 1.2em;
  border-radius: 8px 8px 0 0;
  background-color: #edf9ee;
  padding: 7px 9px;
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    font-size: 1em;
    padding: 5px 7px;
  }
`;


export const UploadSection = styled.div`
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;


export const HiddenInput = styled.input`
  display: none;
`

export const UploadBtn = styled.span`
  white-space: nowrap;
  color: #1d9682;
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    color: white;
    background-color: #1d9682;
  }
`;


export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`

export const Input = styled.input`
  background-color: #fff;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 20px;
  box-sizing: border-box;
  transition:
    border-color 0.2s,
    box-shadow 0.2s;

  &:focus {
    border-color: #aaa;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    outline: none;
  }

  @media (max-width: 768px) {
    padding: 8px;
  }
`;


export const Button = styled.button`
  box-shadow:
    0 1px 2px rgb(60 64 67 / 30%),
    0 1px 3px 1px rgb(60 64 67 / 15%);
  right: 10px;
  top: 50%;
  color: #1d9682;
  font-family: oswald;
  font-size: 15px;
  outline: 2.5px solid #1d9682;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background: #2e4158;
    color: #e9ecef;
    outline: 1px solid #2e4158;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-height: 300px; /* Default for smaller screens */
  overflow-y: auto;
  box-sizing: border-box;
  
  @media (min-width: 1024px) { /* Adjust for large screens */
    max-height: 500px; /* Increase max-height for larger screens */
    gap: 30px; /* Increase gap for more spacious layout */
  }
`;


export const Card = styled.div`
  background-color: #e1ffc7;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  position: relative;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;


export const CardTitle = styled.h3`
  margin: 0 0 10px;
  font-size: 1.1em;
  white-space: nowrap;     
  overflow: hidden;        
  text-overflow: ellipsis;
`;


export const CardText = styled.p`
  margin: 5px 0;
  font-size: 0.9em;
`

export const DeleteIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  color: red;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: darkred;
  }
`

export const NoDocumentsMessage = styled.p`
  text-align: center;
  color: #888;
  font-size: 18px;
  font-style: italic;
  padding: 20px;

  @media (max-width: 600px) {
    font-size: 16px;
    padding: 10px;
  }
`;


export const DownloadLink = styled.a`
  display: inline-block;
  margin-right: 15px;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #0056b3;
  }
`;

export const PreviewLink = styled.a`
  display: inline-block;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #0056b3;
  }
`;

