import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import useAuthState from '../../stores/auth'
import {
  Container,
  Header,
  NoteList,
  NoteItem,
  NoteContainer,
  NoteText,
  NoteTime,
  DateGroup,
  DateHeader,
  OptionsContainer,
  ThreeDots,
  Options,
  Option,
  InputArea,
  EditArea,
  Button,
  TextareaContainer,
  Textarea,
  EditTextArea,
  NoNotesMessage,
} from './MyNotesStyles'
import { format, parseISO } from 'date-fns'

const MyNotes = () => {
  const { user } = useAuthState()
  const [notes, setNotes] = useState([])
  const [newNote, setNewNote] = useState('')
  const [editingNote, setEditingNote] = useState(null)
  const [editingText, setEditingText] = useState('')
  const [activeNote, setActiveNote] = useState(null)
  const noteListRef = useRef(null)
  const menuRef = useRef(null)

  useEffect(() => {
    if (user) {
      fetchNotes()
    }
  }, [user])

  useEffect(() => {
    const handleClickOutside = event => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveNote(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const fetchNotes = async () => {
    try {
      const response = await axios.get(
        `${process.env.GATSBY_API_URL}/users/${user.id}/notes`,
      )
      setNotes(response.data)
    } catch (error) {
      console.error('Error fetching notes:', error)
    }
  }

  const handleAddNote = async () => {
    try {
      await axios.post(`${process.env.GATSBY_API_URL}/users/${user.id}/notes`, {
        text: newNote,
      })
      fetchNotes()
      setNewNote('')
    } catch (error) {
      console.error('Error adding note:', error)
    }
  }

  const handleEditNote = note => {
    setEditingNote(note._id)
    setEditingText(note.text)
    setActiveNote(null)
  }

  const handleUpdateNote = async noteId => {
    try {
      const response = await axios.put(
        `${process.env.GATSBY_API_URL}/users/${user.id}/notes/${noteId}`,
        { text: editingText },
      )
      setNotes(
        notes.map(note =>
          note._id === response.data._id ? response.data : note,
        ),
      )
      setEditingNote(null)
      setEditingText('')
      setActiveNote(null)
    } catch (error) {
      console.error('Error updating note:', error)
    }
  }

  const handleDeleteNote = async noteId => {
    try {
      await axios.delete(
        `${process.env.GATSBY_API_URL}/users/${user.id}/notes/${noteId}`,
      )
      setNotes(notes.filter(note => note._id !== noteId))
      setActiveNote(null)
    } catch (error) {
      console.error('Error deleting note:', error)
    }
  }

  const toggleOptions = noteId => {
    setActiveNote(activeNote === noteId ? null : noteId)
  }

  const groupNotesByDate = notes => {
    return notes.reduce((acc, note) => {
      const date = format(parseISO(note.timestamp), 'yyyy-MM-dd')
      if (!acc[date]) acc[date] = []
      acc[date].push(note)
      return acc
    }, {})
  }

  const groupedNotes = groupNotesByDate(notes)

  if (!user) return <div>Please log in to view your notes.</div>

  return (
    <Container>
      <Header>Your Secure Notes</Header>
      <NoteList ref={noteListRef}>
        {Object.keys(groupedNotes).length > 0 ? (
          Object.keys(groupedNotes).map(date => (
            <DateGroup key={date}>
              <DateHeader>{format(parseISO(date), 'MMMM d, yyyy')}</DateHeader>
              {groupedNotes[date].map(note => (
                <NoteItem key={note._id}>
                  <NoteContainer>
                    {editingNote === note._id ? (
                      <EditArea>
                        <EditTextArea
                          value={editingText}
                          onChange={e => setEditingText(e.target.value)}
                        />
                        <Button onClick={() => handleUpdateNote(note._id)}>
                          Save
                        </Button>
                      </EditArea>
                    ) : (
                      <>
                        <OptionsContainer>
                          <ThreeDots onClick={() => toggleOptions(note._id)}>
                            ⋮
                          </ThreeDots>
                          {activeNote === note._id && (
                            <Options ref={menuRef}>
                              <Option onClick={() => handleEditNote(note)}>
                                Edit
                              </Option>
                              <Option
                                onClick={() => handleDeleteNote(note._id)}
                              >
                                Delete
                              </Option>
                            </Options>
                          )}
                        </OptionsContainer>
                        <NoteText>{note.text}</NoteText>
                        <NoteTime>
                          {format(parseISO(note.timestamp), 'h:mm a')}
                        </NoteTime>
                      </>
                    )}
                  </NoteContainer>
                </NoteItem>
              ))}
            </DateGroup>
          ))
        ) : (
          <NoNotesMessage>
            No notes to display yet. Start adding some!
          </NoNotesMessage>
        )}
      </NoteList>
      <InputArea>
        <TextareaContainer>
          <Textarea
            value={newNote}
            onChange={e => setNewNote(e.target.value)}
            placeholder="Add a new note"
          />
          <Button onClick={handleAddNote}>Add Note</Button>
        </TextareaContainer>
      </InputArea>
    </Container>
  )
}

export default MyNotes
