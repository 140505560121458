import styled from "styled-components"
import TextField from "@material-ui/core/TextField"


export const TabContainer = styled.div`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  
`

export const AfyaContainer = styled.div`
  height: 100%;
  width: 100vw;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding:10px;
  margin-bottom: 2.5rem;
 
  @media screen and (min-width: 320px) {
    height: 100%;
    width: 100%;
  }
  @media screen and (min-width: 768px) {
    height: 100%;
    width: 100%;
  }
  @media screen and (min-width: 1024px) {
    height: 100%;
    width: 100%;
  }
`
export const AfyaCard = styled.div`
  box-shadow: 0 1px 2px 1px #a3d4cb;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  width: 40%;
  border-radius: 8px;
  @media screen and (min-width: 320px) {
    width: 100%;
  }
  @media screen and (min-width: 1024px) {
    width: 70%;
  }
`
export const AfyaForm = styled.form`
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  width: 100%;
`

// export const Table = styled.table`
// height: 200px;
// max-width: 300px;
// margin: 0 auto;
// `

export const AfyaInput = styled(TextField)`
  width: 100%;
  margin: 10px;
  @media screen and (min-width: 320px) {
    margin: 0;
  }
`
export const UpdateBtn = styled.button`
  box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  border-radius: 8px;
  white-space: nowrap;
  color: #1d9682;
  font-size: 15px;
  outline: 2.5px solid #1d9682;
  border: none;
  cursor: pointer;
  padding: 5px 15px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-right: 10px;
  align-self: end;
  margin: 15px;
  &:hover {
    transition: all 0.2sease-in-out;
    background: #2e4158;
    color: #e9ecef;
    outline: 1px solid #2e4158;
  }
`
