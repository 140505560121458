import React from "react"
import PageNav from "../components/homeNav/PageNav"
import UserInfoSection from "../components/userSection/index"
import "../style/settings.css"
import BotNav from "../components/bottomNav"
import Footer from "../components/homeNav/footer/Footer"

export default function settings() {
  return (
    <>
      <PageNav />
      <UserInfoSection />
      <BotNav />
      <Footer />
    </>
  )
}
