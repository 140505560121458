import React from 'react';
import styled from 'styled-components';
import UserForm from '../userSettings/userForm';
import NavTab from './navTab';
import UserHeader from '../header/UserHeader';

const UserCont = styled.div`
  // margin-bottom: 3rem;
`;
const UserWrapper = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0; */
`;

export default function userInfo() {
  return (
    <UserCont>
      <UserWrapper>
        <UserHeader />
        <UserForm />
        <NavTab />
      </UserWrapper>
    </UserCont>
  );
}
