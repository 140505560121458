import React, { useEffect, useRef, useState } from "react"
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom"
import styled from "styled-components"
import useAuthState from "../../stores/auth"

const Analytics = ({ filter, chartId, height, width }) => {
  const { user } = useAuthState()

  const chartDiv = useRef(null)
  const [rendered, setRendered] = useState(false)

  const sdk = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-ppblisting-mlimh",
  })

  const [chart] = useState(
    sdk.createChart({
      chartId: chartId,
      // height: height,
      // width: width,
      theme: "light",
      showAttribution: false,
      maxDataAge: 300,
      autoRefresh: true,
      filter: { "_id": {$oid:user._id} },
    })
  )

  useEffect(() => {
    chart
      .render(chartDiv.current)
      .then(() => setRendered(true))
      .catch(err => console.log("Error during Charts rendering.", err))
  }, [chart])

  useEffect(() => {
    if (rendered) {
      chart
        .setFilter(filter)
        .catch(err => console.log("Error while filtering.", err))
    }
  }, [chart, filter, rendered])

  // useEffect(() => {
  //     const handleResize = () => {
  //       const newWidth = window.innerWidth <= 768 ? "300px" : "700px";
  //       chart.update({ width: newWidth });
  //     };

  //     window.addEventListener("resize", handleResize);
  //     return () => {
  //       window.removeEventListener("resize", handleResize);
  //     };
  //   }, [chart]);

  return <Wrapper ref={chartDiv} />
}

export default Analytics

const Wrapper = styled.div`
  width: 100%;
  height: 500px;
  border-radius: 30px;
  box-shadow: 0 2px 10px 0 rgba(70, 76, 79, 0.2);
  margin: 10px;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    width: 700px;
    height: 700px;
  }
`
